import React from 'react'
import { Grid } from '@mui/material'
import toc from './toc.png'





function HowtoBuy() {
  return (
    <div className='backofdesk '>
<div class="custom-shape-divider-top-1713790199">
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
</svg>
</div>
<div className='container'>
  <br/><br/>   
          <Grid container spacing={2}>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <br/>
<h1 className='maintextosx vert-move'> $frog tokenomics</h1> 
<br/>
          </Grid>
 
       

        <Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

        <div className='container'>
  <div className='cadivs'>
<img src={toc} style={{width:"100px"}}/>

  <h1 className='maintextosxc vert-move'> supply</h1> 
<br/>
<h1 className='hca'>

100 million
</h1>

  </div>


 </div>
</Grid>

<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>
<img src={toc} style={{width:"100px"}}/>
<h1 className='maintextosxc vert-move'> lp burned</h1> 
<br/>
<h1 className='hca'>

100 %
</h1>

</div>


</div>
</Grid>

<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>
<img src={toc} style={{width:"100px"}}/>
<h1 className='maintextosxc vert-move'>tax</h1> 
<br/>
<h1 className='hca'>

0 %
</h1>

</div>


</div>
</Grid>

           </Grid>
          
<br/><br/>



    </div>
    <div class="custom-shape-divider-top-1713790348">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
    </div>
  
  )
}

export default HowtoBuy