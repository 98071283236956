import React from 'react'
import { Grid } from '@mui/material'
import Marquee from "react-fast-marquee";
import dex from './dexc.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import biz from './frok.jpeg'
import Navbar from './NavBar/Navbar'
import def from './def.png'
function Desktop() {
  return (
  <div className='backofdesk'>
   <Navbar/>

<div className='container'>

<Grid container spacing={2}>

<Grid item md={6} lg={6} xs={12} sm={12}>
<br/><br/>
<h1 className='maintext vert-move'>$frog </h1> 
<p className='maintextp vert-move'>the frog of solana
<br/>
<a href='https://dexscreener.com/solana/ax4ephtahg3dpssoukngk24fudtzrzxudchyrzcf4j5n'>
<button class="button-3" role="button">   <img src={def} style={{width:"1.2em" , height:"1.2em"}}/> dex screener</button>
</a>
</p>
  
</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>
<br/><br/>
<div className='centeritall'>
        <img src={biz} className='topimg vert-move'/>
         </div> 
  
</Grid>
</Grid>

</div>
   
<div class="custom-shape-divider-top-1713790348">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
        
      



  </div>
  )
}

export default Desktop