import React from 'react'
import { Grid } from '@mui/material'
import c1 from './fo1.jpeg'
import c2 from './fo2.jpeg'
import c3 from './fo3.jpeg'
import c4 from './fo4.jpeg'
import c5 from './fo5.jpeg'
import c6 from './fo6.jpeg'
import c7 from './foo7.jpeg'
import c8 from './foo8.jpeg'
import c9 from './foo9.jpeg'




function HowtoBuy() {
  return (
    <div className='colorofcontract '>

<div className='container'>
  <br/><br/>   
          <Grid container spacing={2}>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <br/>
<h1 className='maintextosx vert-move'> $frog $frog $frog</h1> 
<br/>
          </Grid>
 
       

        <Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

        <div className='container'>
  <div className='cadivs'>

<h1 className='hca'>

  <img src={c1} style={{width:"100%"}}/>
</h1>

  </div>


 </div>
</Grid>

<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c2} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>

<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c3} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>

<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c4} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>


<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c5} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>


<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c6} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>










<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c7} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>




<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c8} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>





<Grid item md={4} xs={12} lg={4} className='centerall vert-move'>

<div className='container'>
<div className='cadivs'>

<h1 className='hca'>

<img src={c9} style={{width:"100%"}}/>
</h1>

</div>


</div>
</Grid>
           </Grid>
          
<br/><br/>



    </div>

    </div>
  
  )
}

export default HowtoBuy