import React from 'react'
import logos from "./nbl.png";
import { Grid } from '@mui/material';
import './Navbar.css'
import dex from './dexc.png'
import rf from './rf.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
function Navbar() {
  return (
    <div className=''>
   <Grid container spacing={0}>
    <Grid item md={3} lg={3}>
<h1 className='bizlogo'>$frog</h1>

    </Grid>
    <Grid item md={6} lg={6} style={{position:"relative" , top:"20px"}}>


    <div className='centeritall' >

&nbsp;&nbsp;&nbsp;
<a href='https://t.me/tickerisfrog'>
<button class="button-3" role="button">
<FaTelegramPlane/>&nbsp;
  telegram</button>
</a>
&nbsp;&nbsp;&nbsp;
<a href='https://twitter.com/tickerfrogonsol'>
<button class="button-3" role="button">  <FaXTwitter/>&nbsp;twitter</button>
</a>

         </div>


    </Grid>

    <Grid item md={3} lg={3} className='buttonBuy'>
      <a href='https://raydium.io/swap/?outputCurrency=EzCmiXd3FfGCVSfpGhxddPPHTjuUe1arG9M9D3pMLiiu'>



      <button class="button-3" role="button">
        <img src={rf} style={{width:"1.2em" , height:"1.2em"}}/>&nbsp;
        buy $frog</button>





      </a>


    </Grid>
   </Grid>

    </div>
  )
}

export default Navbar