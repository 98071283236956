import React from 'react'
import { Grid } from '@mui/material'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
function Footer() {
  return (
    <div className='backofdesk'>  
    <div class="custom-shape-divider-top-1713790199">
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
</svg>
</div>
<div className='container'>
   
<h1 className='maintext vert-move'>$frog </h1> 
</div>
<br/><br/>

<div className='centeritall' >



<a href='https://twitter.com/tickerfrogonsol'>



<button class="button-33" role="button">

<FaXTwitter/>

</button>





</a>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

<a href='https://t.me/tickerisfrog'>



<button class="button-33" role="button">

<FaTelegramPlane/>

</button>





</a>

</div>

<p className='centerDCa'> © 2024 by $frog all rights reserved!</p>

<br/>
    </div>
  )
}

export default Footer