import React from 'react'
import { Grid } from '@mui/material'
import one from './lof.png'
import ones from './lof.png'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
function Desktopthree() {
  return (
    <div className='colorofcontract'>
<br/>
<h1 className='maintextosmx vert-move'>contract address</h1> 
<br/>
<Grid  container spacing={2}>
<Grid  item md={12} lg={12} xs={12} sm={12}>
 <div className='container vert-move'>
  <div className='cadivs'>

<h1 className='hcams'>EzCmiXd3FfGCVSfpGhxddPPHTjuUe1arG9M9D3pMLiiu</h1>

  </div>


 </div>
</Grid>
</Grid>

<br/>

<br/>
    </div>
  )
}

export default Desktopthree