import React from 'react'
import logos from "./nbl.png";
import dex from './dexc.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { Grid } from '@mui/material';
import rf from './rf.png'
import def from './def.png'
import './Navbar.css'
function Navbar() {
  return (
    <div className=''>
   <Grid container spacing={0}>

  

    <Grid item md={6} lg={6} sm={6} xs={6} className='buttonBuym'>
  
    <a href='https://dexscreener.com/solana/ax4ephtahg3dpssoukngk24fudtzrzxudchyrzcf4j5n'>
<button class="button-3" role="button"  style={{fontSize:"16px"}}> <img src={def} style={{width:"1.3em" , height:"1.3em"}}/>&nbsp;dex screener</button>
</a>


    </Grid>

    <Grid item md={6} lg={6} sm={6} xs={6} className='buttonBuy'>
  
  <a href='https://raydium.io/swap/?outputCurrency=EzCmiXd3FfGCVSfpGhxddPPHTjuUe1arG9M9D3pMLiiu'>
<button class="button-3" role="button" style={{fontSize:"16px"}}> <img src={rf} style={{width:"1.3em" , height:"1.3em"}}/> &nbsp;buy $frog</button>
</a>


  </Grid>
   </Grid>
<br/>
    </div>
  )
}

export default Navbar